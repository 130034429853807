<template>
    <main class="profile-view" :style="{backgroundImage: 'url(' + plainUrl + img_url + ')'}">
      <header class="app-arrow-header text-center no-bg text-white">
        <a href="#" class="app-arrow-header-link left" @click="$router.go(-1);">
          <i class="fas fa-chevron-left"></i>
        </a>
        <h4>Jakie jest Twoje motto?</h4>
        <h5>Uzupełnij swój profil</h5>
      </header>
      <section class="profile-view-content">
        <div class="container">
          <div :class="isMottoEdit ? 'profile-view-motto-edit' : 'profile-view-motto'">
            <div v-if='isMottoEdit === false'>{{motto}}</div>
            <div v-if='isMottoEdit === true'>
              <textarea v-model="motto" class="bigger"></textarea>
            </div>
            <div class="profile-edit-icons">
              <a href="#" class="edit-icon color-white" @click="isMottoEdit = !isMottoEdit">
                <i class="fas fa-pencil-alt"></i>
              </a>
              <a href="#" class="edit-icon color-white" @click="randomQuotes">
                <i class="fas fa-sync-alt"></i>
              </a>
            </div>
          </div>
          <div :class="isNameEdit ? 'profile-view-motto-edit' : 'profile-view-name'" class="d-flex">
            <div>
              <div v-if="isNameEdit === false">{{first_name}}</div>
              <div  v-if="isNameEdit === true">
                <textarea v-model="first_name" class="smaller" placeholder="Imię"></textarea>
              </div>
            </div>
            <a href="#" class="edit-icon color-white" @click="isNameEdit = !isNameEdit">
              <i class="fas fa-pencil-alt"></i>
            </a>
          </div>
          <div :class="isLastnameEdit ? 'profile-view-motto-edit' : 'profile-view-name'" class="d-flex">
            <div>
              <div v-if="isLastnameEdit === false && last_name.length">{{last_name}}</div>
              <div v-if="isLastnameEdit === false && !last_name.length">Nazwisko</div>
              <div  v-if="isLastnameEdit === true">
                <textarea v-model="last_name" class="smaller" placeholder="Nazwisko"></textarea>
              </div>
            </div>
            <a href="#" class="edit-icon color-white" @click="isLastnameEdit = !isLastnameEdit">
              <i class="fas fa-pencil-alt"></i>
            </a>
          </div>
          <div :class="isCityEdit ? 'profile-view-motto-edit' : 'profile-view-location'" class="d-flex">
            <div>
              <div v-if="isCityEdit === false && city.length">{{city}}</div>
              <div v-if="isCityEdit === false && !city.length">Miasto</div>
              <div  v-if="isCityEdit === true">
                <textarea v-model="city" class="small" placeholder="Miasto"></textarea>
              </div>
            </div>
            <a href="#" class="edit-icon color-white" @click="isCityEdit = !isCityEdit">
              <i class="fas fa-pencil-alt"></i>
            </a>
          </div>
          <router-link to="/profil/wybierz-krag">
            <div class="profile-view-footer mt-5">
              <button @click.prevent="submit" class="btn-white-full w-100">Zapisz</button>
            </div>
          </router-link>
        </div>
      </section>
    </main>
</template>
<script>
export default {
  name: 'OnboardingCreate',
  data () {
    return {
      mottoArray: [],
      randomNumber: 0,
      motto: 'motto',
      isMottoEdit: false,
      fullname: '',
      first_name: '',
      isNameEdit: false,
      last_name: '',
      isLastnameEdit: false,
      city: '',
      isCityEdit: false
    }
  },
  created () {
    this.$https('/profile', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true
    })
      .then(res => {
        this.$store.commit('updateUserImg', res.data.img_url)
        let fullname = res.data.username
        fullname = fullname.split(' ')
        this.first_name = fullname[0]
        if (fullname[1]) {
          this.last_name = fullname[1]
        }
      })
      .catch(error => {
        console.log('', error)
      })
    this.$https('/motto', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(response => {
      const getMotto = response.data.response
      this.mottoArray = getMotto.map(e => e.label)
      this.randomQuotes()
    })
      .catch(error => {
        console.log('', error)
      })
  },
  computed: {
    name: {
      get () {
        return this.$store.state.userData.name
      },
      set (value) {
        this.$store.commit('updateUserName', value)
      }
    },
    img_url: {
      get () {
        return this.$store.state.userData.img_url
      }
    }
  },
  methods: {
    randomQuotes () {
      const min = 0
      var max = this.mottoArray.length - 1
      this.randomNumber = Math.floor(Math.random() * (max - min + 1) + min)
      this.motto = this.mottoArray[this.randomNumber]
    },
    submit () {
      if (this.first_name.trim().length && this.last_name.trim().length && this.city.trim()) {
        const userfullname = this.first_name + ' ' + this.last_name
        const getData = `fname=${this.first_name}&lname=${this.last_name}&username=${userfullname}&city=${this.city}&motto=${this.motto}`
        this.$https('/profile', {
          method: 'POST',
          mode: 'no-cors',
          crossDomain: true,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: getData
        }).then(response => {
          this.$router.push({ name: 'OnboardingChoose', params: { name: this.first_name } })
        })
          .catch(error => {
            console.log('', error)
          })
      } else {
        alert('Proszę wypełnić wszystkie pola')
      }
    }
  },
  beforeMount () {
    this.randomQuotes()
  }
}
</script>
<style>
  .profile-view {
    min-height: 100vh;
  }
  .profile-view .profile-view-content{
    bottom: 0;
  }
  .profile-view .profile-view-content .profile-view-motto {
    font-size: 30px;
    line-height: 35px;
  }
  .profile-view-motto-edit textarea {
    font-size: 35px;
    min-height: 0;
    height: 45px;
  }
  @media (max-width: 320px) {
    .profile-view .profile-view-content .profile-view-motto {
      font-size: 20px;
      line-height: 24px;
    }
  }
</style>
